<script setup lang="ts">
interface Props {
  title: string
}

const { title } = withDefaults(defineProps<Props>(), {
  title: '',
})
</script>

<template>
  <header class="bg-white">
    <div class="container print:max-w-none print:!px-0 print:pb-7 mx-auto pt-10 px-4 sm:px-6 lg:px-8">
      <div v-if="!$slots['header-content']" class="flex justify-between">
        <h1 v-if="title" class="text-xl leading-6 font-bold text-gray-900 inline-block">
          {{ title }}
        </h1>
        <span v-else />
        <div>
          <slot />
        </div>
      </div>
      <slot v-else name="header-content" />
    </div>
  </header>
</template>
