import AppSettings from '@app/AppSettings'
import { store } from '@app/store'
import { initializeUserClientData } from '@app/utils/initializeUserClientData'
import {
  CLIENT_SELECT_ROUTE,
  CREATE_CLIENT_ROUTE,
  NO_ACCOUNT_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
} from '@shared/data/constants'
import { debug } from '@shared/utils/logger'
import type { Router } from 'vue-router'
import type { ClientModel } from '@/clients/models/server/ClientModel'
import { usePermissions } from '@/auth/composables/usePermissions'

export function defineMiddleware(router: Router) {
  router.beforeEach(async (to, from) => {
    const {
      isLevel3v2Route,
      clientName,
      LSClientName,
      LSPipelineName,
    } = AppSettings

    debug('Middleware: clientDataLoader')

    if (
      to.name === CLIENT_SELECT_ROUTE
      || to.name === TERMS_OF_SERVICE_ROUTE
      || to.name === CREATE_CLIENT_ROUTE
      || to.name === NO_ACCOUNT_ROUTE
      || isLevel3v2Route.value // This is to bypass this workaround for level3 v2, as level3 v2 routes are displayed in a way we don't need this extra logic
    ) {
      return
    }

    const { level3Enabled } = usePermissions()

    const targetClientName = to.params.clientName || clientName.value

    const myClients: ClientModel[] = store.getters['user/clients'] || []
    const allClients: ClientModel[] = store.getters['user/allClients'] || []
    const clientsList: ClientModel[] = myClients.length ? myClients : allClients
    const existingClient: ClientModel | undefined = myClients.find(
      (client: ClientModel) => targetClientName === client.name,
    ) || myClients?.[0]

    if (level3Enabled.value) {
      // This Probably is better in a middleware. Just be aware of it
      const handleCleanClientSelectionRedirect = async () => {
        LSClientName.value = ''
        LSPipelineName.value = ''
      }
      if (!clientName.value) {
        if (clientsList.length !== 1) {
          await handleCleanClientSelectionRedirect()
          return { name: CLIENT_SELECT_ROUTE }
        }
        LSClientName.value = clientsList[0].name
      }
      else if (!clientsList.length || !existingClient) {
        await handleCleanClientSelectionRedirect()
        return { name: CLIENT_SELECT_ROUTE }
      }
      else {
        LSClientName.value = existingClient.name
      }
    }

    if (
      !to.meta.public
      && clientsList.length > 0
      && !AppSettings.clientDataIsLoaded.value
    ) {
      await initializeUserClientData(to.fullPath)
    }
  })
}
