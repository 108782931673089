import { getUser } from '@/level3/services/user'
import Global from '@/shared/utils/global'

export function useUser() {
  const { data: user, isLoading: isLoadingUser, error } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
    staleTime: 30000,
    refetchOnMount: true,
  })

  watch(error, (error) => {
    if (error) {
      Global.error(error.message)
    }
  })

  return {
    user,
    isLoadingUser,
  }
}
