<script lang="ts" setup>
import { computed } from 'vue'
import type { SelectProps } from 'naive-ui'
import { NSelect } from 'naive-ui'

type FilterSelectThemeOverrides = NonNullable<SelectProps['themeOverrides']>

interface Props {
  selectedClient: string | undefined
  clients: { label: string, value: string }[]
}

const props = defineProps<Props>()

const emit = defineEmits(['update:client'])

const themeOverrides: FilterSelectThemeOverrides = {
  peers: {
    InternalSelectMenu: {
      borderRadius: '7px',
    },
  },
}

const currentClient = computed({
  get: () => props.selectedClient,
  set: value => emit('update:client', value),
})
</script>

<template>
  <NSelect
    v-model:value="currentClient"
    placeholder="Select client"
    filterable
    :options="clients"
    :themeOverrides="themeOverrides"
    :consistentMenuWidth="false"
  >
    <template #action>
      <div class="actions flex justify-between">
        <RouterLink :to="{ name: 'Clients' }">
          <NButton quaternary size="tiny" class="!text-gray-600">
            <template #icon>
              <div class="i-solar-hamburger-menu-outline" />
            </template>
            Client select
          </NButton>
        </RouterLink>
        <RouterLink :to="{ name: 'Create Client' }">
          <NButton quaternary size="tiny" class="!text-gray-600">
            <template #icon>
              <div class="i-solar-add-circle-outline" />
            </template>
            New client
          </NButton>
        </RouterLink>
      </div>
    </template>
  </NSelect>
</template>
