import AppSettings from '@app/AppSettings'
import type { NavigationGuard, RouteLocation, RouteLocationRaw } from 'vue-router'

import type { REPORTING_ROUTE_NAMES } from '@shared/data/constants'
import {
  CLIENT_QUERY_PARAM_NAME,
  CLIENT_SELECT_ROUTE,
  CREATE_CLIENT_ROUTE,
  EMAIL_CONFIRMATION_ROUTE,
  EMAIL_CONFIRMED_ROUTE,
  ERROR_ROUTE,
  NOT_FOUND_ROUTE,
  NO_ACCOUNT_ROUTE,
  PIPELINES_ROUTE,
  PIPELINE_QUERY_PARAM_NAME,
  PRIVILEGES_CLIENTS_ROUTE,
  PRIVILEGES_FEATURES_ROUTE,
  PRIVILEGES_ROLES_ROUTE,
  PRIVILEGES_USERS_ROUTE,
  REPORTING_ROUTES_ARRAY,
  ROLE_TYPE,
  TERMS_OF_SERVICE_ROUTE,
  UNAVAILABLE_ROUTE,
  USECASE_QUERY_PARAM_NAME,
  USER_CLIENT_RELATION_ROUTE,
} from '@shared/data/constants'
import { store } from '@app/store'
import router from './index'
import type { ClientModel } from '@/clients/models/server/ClientModel.ts'
import { useUserPrivilegesForRouter } from '@/privileges/useUserPrivileges.ts'
import AuthService from '@/auth/services/AuthService'
import { usePermissions } from '@/auth/composables/usePermissions'

export const authCallback: NavigationGuard = async (to) => {
  if (store.getters.isUserAuthenticated) {
    const { redirectRouteAfterRecover } = AppSettings
    if (redirectRouteAfterRecover.value.length) {
      const redirect = redirectRouteAfterRecover.value
      redirectRouteAfterRecover.value = ''
      return redirect
    }
    return { path: '/', query: {}, hash: to.hash }
  }
  return false
}

export const guardForEmailConfirmPage: NavigationGuard = (to) => {
  if (!to.query['from-api']) {
    window.location.href = AuthService.getSignInLocation()
    return false
  }
  else {
    return true
  }
}

export const hasClientsToSelectGuard: NavigationGuard = () => {
  const userClients = store.getters['user/allClients'] || store.getters['user/clients'] || []
  if (userClients.length > 1) {
    return true
  }
  return { path: '/' }
}

export const accountAttachedCheckGuard: NavigationGuard = () => {
  const userClients = store.getters['user/allClients'] || store.getters['user/clients'] || []
  if (userClients.length === 0 || store.getters['user/role']?.type === ROLE_TYPE.DEFAULT) {
    return true
  }
  return { path: '/' }
}

export const isAIEorAdmin: NavigationGuard = () => {
  if (store.getters.isUserAuthenticated) {
    const { isAIEorAdmin } = usePermissions()
    return isAIEorAdmin.value ? true : { name: NOT_FOUND_ROUTE }
  }
  else {
    window.location.href = AuthService.getSignInLocation()
    return false
  }
}

export const hasUserPrivilege: NavigationGuard = async (to) => {
  const clientName = computed(() => (store.getters['client/client'] as ClientModel)?.name)
  if (store.getters.isUserAuthenticated) {
    const { isRouteEnabledByName } = await useUserPrivilegesForRouter(clientName)
    return isRouteEnabledByName(to.name?.toString() ?? '') || { name: NOT_FOUND_ROUTE }
  }
  else {
    window.location.href = AuthService.getSignInLocation()
    return false
  }
}

export const isAIEorAdminOrEp: NavigationGuard = () => {
  if (store.getters.isUserAuthenticated) {
    const { isAIEorAdminOrEp } = usePermissions()
    return isAIEorAdminOrEp.value ? true : { name: NOT_FOUND_ROUTE }
  }
  else {
    window.location.href = AuthService.getSignInLocation()
    return false
  }
}

export function populateUrlWithParams(url: string, params: URLSearchParams) {
  const templateHost = 'https://host.org'
  const urlObject = new URL(url.startsWith('http') ? url : `${templateHost}${url}`)
  params.forEach((value, key) => {
    urlObject.searchParams.set(key, value)
  })
  return urlObject.href.replace(templateHost, '')
}

export function processToRoute(to: RouteLocationRaw) {
  const {
    isLevel4Route,
    isLevel3v2Route,
    clientName,
    LSPipelineName,
    usecaseName,
  } = AppSettings

  if (isLevel4Route.value || isLevel3v2Route.value) {
    return to
  }

  const urlParams = new URLSearchParams([])

  const exceptions = {
    routeClient: [
      TERMS_OF_SERVICE_ROUTE,
      UNAVAILABLE_ROUTE,
      NO_ACCOUNT_ROUTE,
      CLIENT_SELECT_ROUTE,
      CREATE_CLIENT_ROUTE,
      ERROR_ROUTE,
      EMAIL_CONFIRMATION_ROUTE,
      EMAIL_CONFIRMED_ROUTE,
      USER_CLIENT_RELATION_ROUTE,
      PRIVILEGES_FEATURES_ROUTE,
      PRIVILEGES_ROLES_ROUTE,
    ],
    routePipeline: [
      TERMS_OF_SERVICE_ROUTE,
      UNAVAILABLE_ROUTE,
      NO_ACCOUNT_ROUTE,
      CLIENT_SELECT_ROUTE,
      CREATE_CLIENT_ROUTE,
      ERROR_ROUTE,
      PIPELINES_ROUTE,
      EMAIL_CONFIRMATION_ROUTE,
      EMAIL_CONFIRMED_ROUTE,
      USER_CLIENT_RELATION_ROUTE,
      PRIVILEGES_USERS_ROUTE,
      PRIVILEGES_CLIENTS_ROUTE,
      PRIVILEGES_FEATURES_ROUTE,
      PRIVILEGES_ROLES_ROUTE,
    ],
    routeUsecase: [
      TERMS_OF_SERVICE_ROUTE,
      UNAVAILABLE_ROUTE,
      NO_ACCOUNT_ROUTE,
      CLIENT_SELECT_ROUTE,
      CREATE_CLIENT_ROUTE,
      ERROR_ROUTE,
      PIPELINES_ROUTE,
      EMAIL_CONFIRMATION_ROUTE,
      EMAIL_CONFIRMED_ROUTE,
      USER_CLIENT_RELATION_ROUTE,
      PRIVILEGES_USERS_ROUTE,
      PRIVILEGES_CLIENTS_ROUTE,
      PRIVILEGES_FEATURES_ROUTE,
      PRIVILEGES_ROLES_ROUTE,
    ],
  }

  const routeName = typeof to === 'string' ? router.resolve({ path: to })?.name as string : router.resolve(to)?.name as string

  if (clientName.value && !exceptions.routeClient.includes(routeName)) {
    urlParams.set(CLIENT_QUERY_PARAM_NAME, clientName.value)
  }

  if (LSPipelineName.value && !to?.query?.pipeline && !exceptions.routePipeline.includes(routeName)) {
    urlParams.set(PIPELINE_QUERY_PARAM_NAME, LSPipelineName.value)
  }

  if (
    usecaseName.value
    && !to?.query?.usecase
    && !exceptions.routeUsecase.includes(routeName)
    && REPORTING_ROUTES_ARRAY.includes(routeName as REPORTING_ROUTE_NAMES)
  ) {
    urlParams.set(USECASE_QUERY_PARAM_NAME, usecaseName.value)
  }

  if (Array.from(urlParams.keys()).length) {
    if (typeof to === 'string') {
      return populateUrlWithParams(to, urlParams)
    }
    return populateUrlWithParams(router.resolve(to)?.href, urlParams)
  }

  return to
}

function getRedirectionToPage(from: string, page: string) {
  return (to: RouteLocation) => {
    return {
      path: to.fullPath.replace(from, page),
    }
  }
}

export const redirectToDataAssetPage = getRedirectionToPage('data-assets', 'data-asset')
export const redirectToUseCasePage = getRedirectionToPage('use-cases', 'use-case')
