import { initL3Routes } from '@router/level3'
import type { Router, RouterScrollBehavior } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import {
  CLIENT_IS_NOT_SUPPORTED,
  CLIENT_SELECT_ROUTE,
  EMAIL_CONFIRMATION_ROUTE,
  EMAIL_CONFIRMED_ROUTE,
  ERROR_ROUTE,
  MAINTENANCE_ROUTE,
  NOT_FOUND_ROUTE,
  NO_ACCOUNT_ROUTE,
  PRODUCT_SELECTION_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  UNAVAILABLE_ROUTE,
  UNAVAILABLE_ROUTE_PATH,
} from '@shared/data/constants'
import {
  accountAttachedCheckGuard,
  authCallback,
  guardForEmailConfirmPage,
} from './routerHelpers'
import OFEDataLoadingModalUtil from '@/level4/components/OFEDataLoadingModalUtil.vue'
import { initL4Routes } from '@/level4/router/level4'
import { initL3v2Routes } from '@/level3/router'

const SelectProductPage = async () => import('@shared/views/SelectProductPage.vue')
const ClientSelectPage = async () => import('@/clients/pages/ClientSelect.page.vue')
const ErrorPage = async () => import('@shared/views/errors/ErrorPage.vue')
const MaintenancePage = async () => import('@shared/views/errors/MaintenancePage.vue')
const NoAccountPage = async () => import('@shared/views/errors/NoAccountPage.vue')
const UnavailablePage = async () => import('@shared/views/errors/UnavailablePage.vue')
const TermsOfServicePage = async () => import('@shared/views/TermsOfServicePage.vue')
const EmailConfirmationPage = async () => import('@shared/views/errors/EmailConfirmationPage.vue')
const EmailConfirmedPage = async () => import('@shared/views/EmailConfirmedPage.vue')
const VerifyAndUnblockUserProxyPage = async () => import('@shared/views/VerifyAndUnblockUserProxy.vue')
const PageNotFound = async () => import('@/shared/views/errors/PageNotFound.vue')

const common_routes = [
  {
    path: '/under-maintenance',
    name: MAINTENANCE_ROUTE,
    component: MaintenancePage,
    meta: {
      public: true,
    },
  },
  {
    path: '/terms-of-service',
    name: TERMS_OF_SERVICE_ROUTE,
    component: TermsOfServicePage,
  },
  {
    path: UNAVAILABLE_ROUTE_PATH,
    name: UNAVAILABLE_ROUTE,
    component: UnavailablePage,
    meta: {
      public: true,
    },
  },
  {
    path: '/account-not-found',
    name: NO_ACCOUNT_ROUTE,
    component: NoAccountPage,
    beforeEnter: accountAttachedCheckGuard,
  },
  {
    path: '/client-selection',
    name: CLIENT_SELECT_ROUTE,
    component: ClientSelectPage,
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => ({}),
    beforeEnter: authCallback,
    meta: {
      public: true,
    },
  },
  {
    path: '/error',
    name: ERROR_ROUTE,
    component: ErrorPage,
    meta: {
      public: true,
    },
  },
  {
    path: '/email-confirm',
    name: EMAIL_CONFIRMATION_ROUTE,
    component: EmailConfirmationPage,
    beforeEnter: guardForEmailConfirmPage,
    meta: {
      public: true,
    },
  },
  {
    path: '/email-confirmed',
    name: EMAIL_CONFIRMED_ROUTE,
    component: EmailConfirmedPage,
    meta: {
      bypassTOS: true,
      public: true,
    },
  },
  {
    path: '/not-supported',
    name: CLIENT_IS_NOT_SUPPORTED,
    component: OFEDataLoadingModalUtil,
    meta: {
      public: true,
    },
  },
  {
    path: '/verify-and-unblock-user',
    name: 'VerifyAndUnblockUser',
    component: VerifyAndUnblockUserProxyPage,
  },
  {
    path: '/select-product',
    name: PRODUCT_SELECTION_ROUTE,
    component: SelectProductPage,
  },
  {
    path: '/404',
    name: NOT_FOUND_ROUTE,
    component: PageNotFound,
    meta: {
      public: true,
    },
  },
  {
    path: '/:catchAll(.*)*',
    name: NOT_FOUND_ROUTE,
    component: PageNotFound,
  },
]

const scrollBehavior: RouterScrollBehavior = async (to, from, savedPosition) => {
  if (to.name === from.name) {
    to.meta?.scrollPos && (to.meta.scrollPos.top = 0)
    return { left: 0, top: 0 }
  }
  const scrollpos = savedPosition || to.meta?.scrollPos || { left: 0, top: 0 }
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(scrollpos)
    }, 10)
  })
}

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: common_routes,
  scrollBehavior,
})

export function initializeLevelRoutes(level: 3 | 4) {
  if (level === 3) {
    initL3Routes(router)
    initL3v2Routes(router)
  }
  if (level === 4) { initL4Routes(router) }
}

for (
  const i
  of Object.values(import.meta.glob<{ defineMiddleware: (route: Router) => any }>('../middlewares/*.ts', { eager: true }))) {
  void i.defineMiddleware?.(router)
}

export default router
