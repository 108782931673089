import EmptyLayout from '@layouts/EmptyLayout.vue'
import type { RouteRecordRaw, Router } from 'vue-router'
import posthog from 'posthog-js'
import ClientsWrapper from '../components/ClientsWrapper.vue'
import ClientsPage from '@/level3/pages/clients/index.vue'

export enum Level3Route {
  CLIENTS = 'Clients',
  CREATE_CLIENT = 'Create Client',
  CLIENT = 'Client',
  PIPELINES = 'Pipelines',
  PIPELINE = 'Pipeline',
  DATA_ASSETS = 'Data Assets',
}

function getLevel3v2Routes(): RouteRecordRaw[] {
  return [
    {
      path: '/clients',
      name: Level3Route.CLIENTS,
      component: ClientsWrapper,
      beforeEnter: async () => {
        // We cannot use the Store here as this is not wrapped under script
        // We are using the posthog-js library to get the feature flags directly in this case
        return new Promise(resolve => posthog.onFeatureFlags((flags) => {
          if (!flags.includes('enable-level-3-v2')) {
            return resolve({ path: '/client-selection' })
          }
          else {
            resolve(true)
          }
        }))
      },
      children: [
        {
          path: '',
          meta: { title: 'Clients' },
          component: ClientsPage,
        },
        {
          path: 'create',
          name: Level3Route.CREATE_CLIENT,
          meta: { title: 'Create Client' },
          component: ClientsPage,
        },
        {
          path: ':clientSlug',
          name: Level3Route.CLIENT,
          meta: { title: 'Client' },
          redirect: (to) => {
            return `${to.path}/pipelines`
          },
          children: [
            {
              path: 'pipelines',
              name: Level3Route.PIPELINES,
              meta: { title: 'Pipelines' },
              redirect: (to) => {
                return `${to.path}/default`
              },
              children: [
                {
                  path: ':pipelineSlug',
                  name: Level3Route.PIPELINE,
                  meta: { title: 'Pipeline' },
                  redirect: (to) => {
                    return `${to.path}/data-assets`
                  },
                  children: [
                    {
                      path: 'data-assets',
                      name: Level3Route.DATA_ASSETS,
                      meta: { title: 'Data Assets' },
                      component: EmptyLayout,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}

export function initL3v2Routes(localRouter: Router) {
  const level3Routes = getLevel3v2Routes()
  level3Routes.forEach(r => localRouter.addRoute(r))
}
