<script setup lang="ts">
import PageHeader from './PageHeader.vue'
import Navigation from './Navigation.vue'

const title = ref('')
</script>

<template>
  <Navigation />

  <PageHeader :title="title">
    <template v-if="$slots['header-content']" #header-content>
      <slot name="header-content" />
    </template>
    <slot name="header-right" />
  </PageHeader>

  <main class="main-container">
    <div
      class="mx-auto px-4 pb-14 print:max-w-none lg:px-8 sm:px-6 print:!px-0"
    >
      <router-view />
    </div>
  </main>
  <!-- <ThePageFooter /> -->
</template>
