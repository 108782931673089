import {
  chartBaseColors,
  chartColorBlindColors,
} from '@/accessibility/data/chartColors'

export default function useAccessibility() {
  const bodyElement = document.querySelector('body')

  const isColorBlindnessModeEnabled = useStorage(
    'offerfit/accessibility/colorBlindnessMode',
    false,
  )

  const accessibleChartColors = computed(() => {
    return isColorBlindnessModeEnabled.value ? chartColorBlindColors : chartBaseColors
  })

  const isReadableFontEnabled = useStorage('offerfit/accessibility/readableFont', false)
  const fontFamily = useCssVar('--offerfit-font-family', bodyElement)

  watch(
    isReadableFontEnabled,
    (value) => {
      if (value) {
        fontFamily.value = 'Inter'
      }
      else {
        fontFamily.value = 'Jost'
      }
    },
    { immediate: true },
  )

  return {
    isColorBlindnessModeEnabled,
    accessibleChartColors,
    isReadableFontEnabled,
  }
}
